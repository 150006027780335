<template>
	<div v-if="canAddFolder">
		<v-tooltip v-if="simpleButtonDisplay && !listTileDisplay" bottom>
			<template v-slot:activator="{ on }">
				<v-btn icon @click="showDialog" v-on="on">
					<v-icon color="primary" class="mr-1">create_new_folder</v-icon>
				</v-btn>
			</template>
			<span v-t="'documents.create_folder'" />
		</v-tooltip>
		<v-list-tile v-if="listTileDisplay" avatar @click="showDialog">
			<v-list-tile-avatar>
				<v-icon color="primary">create_new_folder</v-icon>
			</v-list-tile-avatar>
			<v-list-tile-content>
				<v-list-tile-title v-t="'documents.create_folder'" />
			</v-list-tile-content>
		</v-list-tile>
		<FolderCreator v-if="addFolderDialog" v-model="model" :parent-folder=parentFolder @close="closeDialog" />
	</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import DocumentActionModuleGuard from '@/mixins/ModulesGuards/Documents/Actions/DocumentActionModuleGuard'

const name = 'AddFolderButton'

export default {
	name,
	components: {
		FolderCreator: () => ({
			component: import('@/components/Documents/Actions/Add/FolderCreator')
		})
	},
	mixins: [DocumentActionModuleGuard],
	props: {
		openWithEvent: {
			default: false,
			required: false,
			type: Boolean
		},
		value: {
			type: Object,
			required: false,
			default: null
		},
		selectedElements: {
			type: Array,
			required: false,
			default: null
		}
	},
	data: function () {
		return {
			addFolderDialog: false
		}
	},
	computed: {
		...mapState({
			isGuest: state => state.company.userRoles.isGuest,
			pauseKeyboardListening: state => state.pauseKeyboardListening,
			currentDialog: state => state.dialogs.current,
		}),
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		parentFolder: function () {
			return this.value?.is_folder ? this.value : null
		},
		canAddFolder: function () {
			return (
				!this.isGuest &&
				this.parentFolder &&
				this.parentFolder.is_folder &&
				!this.parentFolder.is_search_results &&
				!this.parentFolder.is_readonly &&
				!this.parentFolder.is_trash &&
				(!this.selectedElements || this.selectedElements.length == 0)
			)
		}
	},
	methods: {
		...mapActions([
			'setPauseKeyboardListening',
			'setPreventDefaultKeyboardEvents',
		]),
		...mapMutations({
			setCurrentDialog: 'dialogs/setCurrent'
		}),
		showDialog: function (event) {
			if (this.pauseKeyboardListening) {
				return;
			}
			if (this.currentDialog !== null && this.currentDialog !== name) {
				return;
			}
			if (event instanceof KeyboardEvent && event.target !== document.body) {
				return;
			}
			this.setPreventDefaultKeyboardEvents(false)
			this.setPauseKeyboardListening(true)

			if (this.canAddFolder) {
				this.setCurrentDialog(name)
				setTimeout(() => (this.addFolderDialog = true), 0)
			}
		},
		closeDialog: function () {
			this.setPauseKeyboardListening(false)
			this.setPreventDefaultKeyboardEvents(true)
			this.setCurrentDialog(null)
			this.addFolderDialog = false
		}
	}
}
</script>
